import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const columns = [
  { id: 'phone', label: 'Phone No' },
  { id: 'client_code', label: 'Client Code' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email Address' },
  { id: 'registerd_on', label: 'Registered On' },
  { id: 'updated_on', label: 'Updated On' },
  { id: 'action', label: 'Action' },
];

const CustomersTable = ({ searchInput, handleEditClick }) => {
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [holdings, setHoldings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInputVar, setSearchInputVar] = useState('');
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;

  useEffect(() => {
    setSearchInputVar(searchInput);
    setController({
      page: 0,
      rowsPerPage: 20
    });
  }, [searchInput]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let searchURL = '';
        if (searchInput) {
          searchURL = `&search_value=${searchInput}`;
        }

        const response = await fetch(
          `${URL}/clients/?limit=${controller.rowsPerPage}&offset=${controller.page * controller.rowsPerPage}${searchURL}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setHoldings(jsonData.results);
        setRowCount(jsonData.count);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [controller, searchInput]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  return (
    <Paper sx={{ width: 'auto !important', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{
                    minWidth: column.minWidth,
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : holdings.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No match found
                </TableCell>
              </TableRow>
            ) : (
              holdings.map((client, index) => (
                <TableRow key={index} cid={client.id}>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {client.phone_no}
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {client.client_code}
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {client.client_name}
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {client.email ? client.email : '-'}
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    -
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {client.updated_at}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                  >
                    <Button variant="contained" onClick={(e) => handleEditClick(e, client)}>Edit</Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default CustomersTable;
