import * as React from "react";
import { useState, useEffect } from 'react';
import Header from './Header.js';
import ButtonRow from "./ButtonRow.js";
import SearchInputButton from "./SearchInputButton.js";
import DatePickerAndButtons from "./DatePickerAndButtons.js";
import UploadHistory from "./UploadHistory.js";
import CustomersHome from "./Customers/CustomersHome.js";
import Container from '@mui/material/Container';
import FileUploadData from './FileUploadData.js'
import Footer from "./Footer.js";
import { Routes, Route } from 'react-router-dom';

const Home = (props) => {
  const [refreshKey, setRefreshKey] = useState(0); // State to force refresh

  const handleUploadSuccess = () => {
    setRefreshKey(oldKey => oldKey + 1); // Update the refresh key to force re-render
  };

  return (
    <>  
        {/* <Header userState={props.userState} /> */}
        <Container maxWidth={false}>
        {/* <p>State: {props.userState} Time remaining: {props.remaining}</p> */}
          {/* <ButtonRow onUploadSuccess={handleUploadSuccess} /> */}
          <FileUploadData refreshKey={refreshKey} />
        </Container>
        {/* <Footer /> */}
    </>
  );
}
export default Home;
